import EventBus from 'js-event-bus';
import { mapValues } from 'lodash';

const eventBus = new EventBus();

export function subscribe(eventName, callback){
  eventBus.on(eventName, callback);
  return callback;
}
export function unsubscribe(eventName, callback){
  eventBus.detach(eventName, callback);
}
export function emit(eventName, params){
  eventBus.emit(eventName, null, params);
}

// this.unsubscribeAll = subscribeAll(this, {'myEventHappened', this.onItHasHappened})
// this.unsubscribeAll()
export function subscribeAll(controller, unboundFuncBySubscriptionName){
  // Bind each function to the controller
  let boundFuncBySubscription = mapValues(unboundFuncBySubscriptionName, (callback, eventName) => {
    return subscribe(eventName, callback.bind(controller));
  });

  // Return a function that will unsubscribe all of the bound functions
  function unsubscribeAll(){
    Object.entries(boundFuncBySubscription).forEach(([eventName, callback]) => {
      unsubscribe(eventName, callback);
    });
  }
  
  return unsubscribeAll;
}
