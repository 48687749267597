import { Controller } from '@hotwired/stimulus';

// this controller is used to store the user scroll direction in local storage
// which will be used in message controller to decide either user should be scrolled to the bottom
// or not when message is being streaming
export default class extends Controller {
  static targets = ['scrollable', 'scrollToBottom', 'content', 'newMessage'];

  connect() {
    this.initObserver();
    this.hideScrollToBottom();
  }

  initObserver() {
    this.ro = new ResizeObserver(this.resizeHandler.bind(this));
    this.chatText = this.contentTarget;
    this.chat = this.chatText.parentNode;
    this.chat.addEventListener('scroll', this.scrollHandler.bind(this));
    this.observeResize();
  }

  disconnect() {
    this.ro.disconnect();
    this.chat.removeEventListener('scroll', this.scrollHandler);
  }

  resizeHandler() {
    // Scroll to bottom, unless user has scrolled.
    if (!this.chat.saveUserScroll) {
      this.hideScrollToBottom();
      this.chat.isResizeScrollEvent = true;
      this.chat.scrollTop = this.chat.scrollHeight - this.chat.clientHeight;
    }
  }

  scrollHandler() {
    // Ignore scrolls generated by ResizeObserver
    if (this.chat.isResizeScrollEvent) {
      delete this.chat.isResizeScrollEvent;
      return;
    }

    // Save user's position unless scrolled to the bottom,
    if (this.chat.scrollTop !== this.chat.scrollHeight - this.chat.clientHeight) {
      this.chat.saveUserScroll = true;
      this.showScrollToBottom();
    } else {
      this.hideScrollToBottom();
      this.chat.saveUserScroll = false;
    }
  }

  observeResize() {
    this.ro.observe(this.chatText);
  }

  scrollToBottom() {
    this.scrollableTarget.scrollTo({
      top: this.scrollableTarget.scrollHeight,
      behavior: 'smooth'
    });
    setTimeout(() => {
      this.chat.scrollTop = this.chat.scrollHeight - this.chat.clientHeight;
      this.hideScrollToBottom();
      this.chat.isResizeScrollEvent = true;
    }, 500);
  }

  showScrollToBottom() {
    this.scrollToBottomTarget.classList.remove('d-none');
  }

  hideScrollToBottom() {
    this.scrollToBottomTarget.classList.add('d-none');
  }

  // uncomment this code, if we want to scroll to the bottom when new message is generated
  // newMessageTargetConnected() {
  //   this.hideScrollToBottom();
  //   this.chat.saveUserScroll = false;
  //   this.resizeHandler.bind(this);
  // }
}
