import { memoize } from 'lodash';

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name='${name}']`);
  if (element) {
    return element.getAttribute('content');
  }
}

export function findElement(root, selector) {
  if (typeof root == 'string') {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// like Rails number_to_human_size
// from: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function isIOS() {
  return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

const isMobileDeviceMemoized = memoize(function () {
  // from Shake
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    console.debug(e);
    return false;
  }
});

export function isMobileDevice() {
  return isMobileDeviceMemoized();
}

export * as searchParamsHelper from './search_params_helper';
