import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';
export default class extends Controller {
  static targets = ['copyButton', 'copySource'];
  static values = {
    successDuration: {
      type: Number,
      default: 2000
    },
    url: String
  };

  connect() {
    if (!this.hasCopyButtonTarget) return;
    this.originalContent = this.copyButtonTarget.innerText;
  }

  copy(event) {
    event.preventDefault();

    if (this.hasCopySourceTarget) {
      return navigator.clipboard.writeText(
        this.copySourceTarget.innerHTML || this.copySourceTarget.value
      );
    }

    navigator.clipboard.writeText(this.text).then(() => this.copied(this.restoreContent));
  }

  get restoreContent() {
    if (!this.hasCopyButtonTarget) return null;

    if (this.copyButtonTarget.dataset.copyToClipboardRestoreHtml) {
      return this.copyButtonTarget.innerHTML;
    }

    return this.copyButtonTarget.innerText;
  }

  get text() {
    if (this.hasCopySourceTarget) {
      return this.copySourceTarget.innerText || this.copySourceTarget.value;
    }

    if (this.hasCopyButtonTarget && this.copyButtonTarget.dataset.copyToClipboardContent) {
      return this.copyButtonTarget.dataset.copyToClipboardContent;
    }

    return '';
  }

  copy_and_send_message(event) {
    event.preventDefault();

    const text = this.copySourceTarget.innerHTML || this.copySourceTarget.value;
    if (!this.urlValue) {
      const response = post(this.urlValue, { query: { link: text } });
      if (response.ok) {
        console.log('Message Sent');
      }
    }

    navigator.clipboard
      .writeText(text)
      .then(() => this.copied_and_update_span(this.copyButtonTarget.innerHTML || 'Copy'));
  }

  copyImageSeed(event) {
    event.preventDefault();

    const text = this.copySourceTarget.innerHTML || this.copySourceTarget.value;

    navigator.clipboard.writeText(text);
  }

  copied(defaultValue) {
    if (!this.hasCopyButtonTarget) return;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.copyButtonTarget.innerHTML = this.data.get('successContent');

    this.timeout = setTimeout(() => {
      this.copyButtonTarget.innerHTML = defaultValue;
    }, this.successDurationValue);
  }

  copied_and_update_span(defaultValue) {
    if (!this.hasCopyButtonTarget) return;

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.copyButtonTarget.getElementsByTagName('span')[0].innerText =
      this.data.get('successContent');

    this.timeout = setTimeout(() => {
      this.copyButtonTarget.innerHTML = defaultValue;
    }, this.successDurationValue);
  }
}
