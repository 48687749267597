import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="legal-disclaimer-init"
export default class extends Controller {
  connect() {
    let body = document.getElementsByTagName('body')[0];
    let disclaimer = document.getElementById('legal-disclaimer');
    let animationInterval;

    body.addEventListener('mousemove', function () {
      disclaimer.style.opacity = 0;
      clearInterval(animationInterval);
      animationInterval = setInterval(fadeIn, 1000);
    });

    function fadeIn() {
      disclaimer.style.opacity = 1;
    }
  }
}
