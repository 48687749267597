import { Controller } from '@hotwired/stimulus';
import { debounce, isEmpty } from 'lodash';
import { findElement } from '../helpers';
export default class extends Controller {
  static values = {
    datatableId: String
  };

  static targets = [
    'keywordInput',
    'searchForm',
    'keywordsHiddenInput',
    'sortHiddenInput',
    'searchClear',
    'filterCount'
  ];

  searchformSubmitDebounceFunc = null;
  clearDebounceFunc = null;
  postFrameLoadDebounceFunc = null;
  sorting = null;
  keywords = null;
  filterInputs = null;

  connect() {
    this.init();
  }

  init() {
    this.filterInputs = this.element.querySelectorAll('input[name*="filters"]');
    this.setAttributesFromSearchParams(window.location.search);

    this.postFrameLoadDebounceFunc = debounce(this.setAttributesFromSearchParams, 300);
    this.element.addEventListener('turbo:frame-load', (event) => {
      this.filterInputs.forEach((elem) => {
        elem.disabled = false;
      });
      this.postFrameLoadDebounceFunc(new URL(event.target.src).search);
    });

    this.searchformSubmitDebounceFunc = debounce(() => {
      if (!isEmpty(this.keywords)) {
        this.keywordsHiddenInputTarget.disabled = false;
        this.keywordsHiddenInputTarget.value = this.keywords;
      } else {
        this.keywordsHiddenInputTarget.disabled = true;
      }

      if (!isEmpty(this.sorting)) {
        this.sortHiddenInputTarget.disabled = false;
        this.sortHiddenInputTarget.value = this.sorting;
      } else {
        this.sortHiddenInputTarget.disabled = true;
      }

      this.filterInputs.forEach((elem) => {
        if (isEmpty(elem.value)) {
          elem.disabled = true;
        }
      });

      this.searchFormTarget.requestSubmit();
    }, 300);

    this.clearDebounceFunc = debounce(() => {
      if (this.hasSearchClearTarget) {
        this.searchClearTarget.classList.add('d-none');
      }
      this.keywordInputTarget.value = '';
    }, 400);
  }

  handleKeyup() {
    if (this.postFrameLoadDebounceFunc) {
      this.postFrameLoadDebounceFunc.cancel();
    }

    if (this.hasSearchClearTarget) {
      if (isEmpty(this.keywordInputTarget.value)) {
        this.searchClearTarget.classList.add('d-none');
      } else {
        this.searchClearTarget.classList.remove('d-none');
      }
    }

    this.keywords = this.keywordInputTarget.value;
    this.searchformSubmitDebounceFunc();
  }

  clearSearchInput() {
    this.keywords = '';
    this.searchformSubmitDebounceFunc();
    this.clearDebounceFunc();
  }

  clearFilters() {
    document.querySelectorAll('[data-flatpickr-target="main"]').forEach(function (input) {
      if (input._flatpickr) {
        input._flatpickr.clear();
      }
    });

    this.filterInputs.forEach((elem) => {
      if (elem.type === 'checkbox') {
        elem.checked = false;
      } else if (!isEmpty(elem.value)) {
        elem.value = null;
      }
    });

    this.applyFilters();
  }

  applyFilters() {
    this.filterInputs.forEach((elem) => {
      if (isEmpty(elem.value)) {
        elem.disabled = true;
      }
    });
    this.searchformSubmitDebounceFunc();
  }

  sort(event) {
    let th = event.target;
    let columnName = th.dataset.sortColumnName;

    if (th.classList.value.match(/\bsorting\b/)) {
      this.sorting = `${columnName}:asc`;
    } else if (th.classList.value.match(/\bsorting_asc\b/)) {
      this.sorting = `${columnName}:desc`;
    } else if (th.classList.value.match(/\bsorting_desc\b/)) {
      this.sorting = '';
    }

    this.searchformSubmitDebounceFunc();
  }

  setAttributesFromSearchParams(search) {
    let searchParams = new URLSearchParams(search);
    this.sorting = searchParams.get('sort');
    this.keywords = searchParams.get('keywords');
    let searchKeys = Array.from(searchParams.keys());
    let appliedFilters = searchKeys.filter((key) => key.includes('filters'));

    if (this.hasFilterCountTarget) {
      if (appliedFilters.length) {
        this.filterCountTarget.innerText = `(${appliedFilters.length})`;
      } else {
        this.filterCountTarget.innerText = '';
      }
    }

    if (!isEmpty(this.keywords) && this.keywords !== '*') {
      this.keywordInputTarget.value = this.keywords;
      if (this.hasSearchClearTarget) {
        this.searchClearTarget.classList.remove('d-none');
      }
    }

    if (this.hasDatatableIdValue) {
      this._updateColumnSorting();
    }

    if (this.filterInputs) {
      searchParams.forEach((value, key) => {
        this.filterInputs.forEach((input) => {
          if (input.value === value && input.name === key) {
            input.checked = true;
          }
        });
      });
    }
  }

  // for datatables on view only
  _updateColumnSorting() {
    if (isEmpty(this.sorting)) {
      return;
    }

    let name = this.sorting.split(':')[0];
    let direction = this.sorting.split(':')[1];
    let th = findElement(this.element, `[data-sort-column-name="${name}"]`);
    if (th) {
      let className = `sorting_${direction}`;
      th.classList.remove('sorting');
      th.classList.add(className);
    }
  }
}
