import { Controller } from '@hotwired/stimulus';
import { filter, debounce } from 'lodash';

// Reset - set form state back to how it started (which might be custom values that use set previously)
// Clear - Remove all values

export default class extends Controller {
  static targets = [
    'form',
    'resetButton',
    'hiddenDelete',
    'deleteButton',
    'submitButton',
    'saveButton',
    'confirmToggle'
  ];

  static values = {
    resetOnHideModalId: String
  };

  connect() {
    this.element.addEventListener('reset', (event) => this.onReset(event));
    // Support resetting the form when the modal with a specific id has hidden
    if (this.resetOnHideModalIdValue) {
      document
        .querySelector(this.resetOnHideModalIdValue)
        .addEventListener('hidden.bs.modal', () => {
          this.reset();
        });
    }

    this.persistResetInputValues();
    this.removeSaveSuccessful();
  }

  enableSubmitIfToggleOn() {
    if (this.hasConfirmToggleTarget) {
      let on = this.confirmToggleTarget.checked;
      if (this.hasSubmitButtonTarget) {
        on
          ? this.submitButtonTarget.classList.remove('disabled')
          : this.submitButtonTarget.classList.add('disabled');
      }
    }
  }

  saveButtonTargetConnected() {
    this.element.addEventListener('dropzone:complete', (event) => {
      this.toggleSaveButton(event.detail);
    });
    this.element.addEventListener('dropzone:addedfiles', (event) => {
      this.toggleSaveButton(event.detail);
    });
  }

  // currently only used for avatar update on agency users
  toggleSaveButton(event) {
    let { enable } = event;
    if (this.hasSaveButtonTarget) {
      if (enable) {
        this.saveButtonTarget.removeAttribute('disabled');
      } else {
        this.saveButtonTarget.setAttribute('disabled', true);
      }
    }
  }

  checkUploaderToPermitAction(event) {
    // assumes dropzone preview ul
    let preview = document.querySelector(event.currentTarget.dataset.previewSelectorClass);

    let required = event.currentTarget.dataset.required == 'true';
    let liCount = preview.querySelectorAll('li').length;
    if (liCount == 0 && required) {
      if (this.hasSaveButtonTarget) {
        this.saveButtonTarget.classList.add('disabled');
      }

      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.classList.add('disabled');
      }
    } else {
      if (this.hasSaveButtonTarget) {
        this.saveButtonTarget.classList.remove('disabled');
      }

      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.classList.remove('disabled');
      }
    }
  }

  setHiddenDeleteValueAndSubmit(event) {
    event.preventDefault();
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.classList.add('d-none');
    }

    if (this.hasDeleteButtonTarget) {
      this.deleteButtonTarget.disabled = true;
    }

    if (this.hasHiddenDeleteTarget) {
      this.hiddenDeleteTarget.value = 1;
      this.submitForm();
    }
  }

  submitFormNoDebounce() {
    if (this.hasFormTarget) {
      this.formTarget.submit();
    }
  }

  get hiddenInputForSave() {
    let input = document.createElement('input');
    input.setAttribute('name', 'commit');
    input.setAttribute('value', 'Save & Preview');
    input.setAttribute('type', 'hidden');
    return input;
  }

  // handling showing loader state for wizard buttons
  onClick(event) {
    let saveButtonClicked = this.hasSaveButtonTarget
      ? event.currentTarget == this.saveButtonTarget
      : false;
    let form;
    if (this.hasSaveButtonTarget) {
      if (saveButtonClicked) {
        this.saveButtonTarget.setAttribute('disabled', true);
        this.formTarget.appendChild(this.hiddenInputForSave);
        if (this.hasSubmitButtonTarget) {
          this.submitButtonTarget.classList.add('d-none');
        }
      } else {
        this.saveButtonTarget.classList.add('d-none');
        if (this.hasSubmitButtonTarget) {
          this.submitButtonTarget.setAttribute('disabled', true);
        }
      }
    } else if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.setAttribute('disabled', true);
      if (this.submitButtonTarget.dataset.disableWith) {
        let classes = JSON.parse(this.submitButtonTarget.dataset.disableWith);
        classes.forEach((klass) => this.submitButtonTarget.classList.add(klass));
      }
    } else if (event.currentTarget.dataset.formId) {
      form = document.getElementById(event.currentTarget.dataset.formId);
      event.currentTarget.setAttribute('disabled', true);
    }
    this.submitForm(saveButtonClicked, form);
  }

  submitForm(save_clicked = false, form = null) {
    if (this.hasFormTarget) {
      if ((this.hasSubmitButtonTarget && this.submitButtonTarget.tagName !== 'A') || save_clicked) {
        this.debouncedFormRequestSubmit();
      }
    } else if (form) {
      form.requestSubmit();
    }
  }

  get debouncedFormRequestSubmit() {
    return debounce(() => this.formTarget.requestSubmit(), 500); // important to use requestSubmit for turbo submit
  }

  removeSaveSuccessful() {
    const buttons = this.element.querySelectorAll('[data-save-successful=true]');
    if (buttons) {
      setTimeout(function () {
        buttons.forEach((element) => {
          element.setAttribute('data-save-successful', '');
        });
      }, 500);
    }
  }

  persistResetInputValues() {
    this.inputs.forEach((input) => {
      if (!input.dataset.resetValue && !input.dataset.noClearOrReset) {
        input.dataset.resetValue = ['checkbox', 'radio'].includes(input.type)
          ? input.checked
          : input.value;
      }
    });
  }

  get inputs() {
    // only certain types of inputs to exclude things like buttons
    return filter(this.element.querySelectorAll('input'), ({ type }) => {
      return ['checkbox', 'radio', 'text', 'number', 'range', 'hidden'].includes(type);
    });
  }

  reset() {
    this.element.reset();
  }

  onReset(event) {
    // avoid default form reset behavior
    event.preventDefault();

    this.setAllInputs('resetValue');
  }

  clear() {
    this.setAllInputs('clearValue');
  }

  setAllInputs(propertyName) {
    // Set all supported inputs to their data propertyName value, or blank
    this.inputs.forEach((input) => {
      if (!input.dataset.noClearOrReset) {
        let value = input.dataset[propertyName] || '';
        if (['checkbox', 'radio'].includes(input.type)) {
          input.checked = value === 'true';
        } else {
          input.value = value;
        }
      }
    });

    this.dispatchChangeEvents();
  }

  dispatchChangeEvents() {
    let customEvent = new CustomEvent('custom-change', {});

    this.inputs.forEach((input) => {
      // dispatching a custom event so that other Stimulus controllers can be aware of the change
      input.dispatchEvent(customEvent);
    });
  }

  displayReset() {
    if (this.hasResetButtonTarget) {
      this.resetButtonTarget.classList.remove('invisible');
      this.resetButtonTarget.classList.remove('pe-none');
    }
  }
}
