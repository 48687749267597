/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from '@rails/activestorage';
import { getMetaValue, findElement, removeElement, insertAfter } from '../helpers';
import { getAcceptedFilesFromFeature } from '../helpers/accepted_file_types';
import { showToast } from '../helpers/toast_functions';
import { emit } from '../helpers/eventbus';
import { Dropzone } from 'dropzone';
import { last, includes, without, mean, round } from 'lodash';
import { post, destroy } from '@rails/request.js';

export default class extends Controller {
  static targets = ['input'];
  static values = {
    config: String,
    uploadIndex: Number,
    fileHiddenInputName: String,
    disableClass: String,
    updateImageOnFinish: Boolean,
    portraitAudioUploaded: Boolean,
    portraitImageUploaded: Boolean,
    isClipdropBackgroundRemoval: Boolean,
    isModelTraining: Boolean,
    isVoiceTraining: Boolean,
    speechAudioUploaded: Boolean
  };

  remainingFiles = [];
  totalFiles = 0;
  processedFiles = 0;
  progressByFileUUID = {};

  connect() {
    this.dropZoneConfig = JSON.parse(this.configValue);
    if (this.dropZoneConfig.initSort) {
      this.setSortable();
    }
    this.dropZone = this.createDropZone();
    this.hideFileInput();
    this.bindEvents();
    if (this.previewElement && this.previewElement.querySelector('.avatar-dropzone')) {
      this.hideAvatarUpload();
    }
  }

  setSortable() {
    let sortableUl = findElement(this.element, '#dropzone-sortable-list');
    let sortable = HSCore.components.HSSortable.collection;
    let existing = sortable.find((elm) => elm.$el === sortableUl);
    if (!existing) {
      HSCore.components.HSSortable.init(sortableUl);
      existing = HSCore.components.HSSortable.collection.find((elm) => elm.$el === sortableUl);
    }

    // to be used in subsequent tasks if needed
    this.sortable = existing.$initializedEl;
  }

  triggerInput() {
    this.dropZone?.element?.click();
  }

  removeEffectFromPreviousUpload() {
    let domElement = findElement(this.element, `.${this.disableClassValue}`);
    if (domElement) {
      domElement.classList.remove(this.disableClassValue);
    }
    this.dropZone.options.maxFiles++; // there is a bug here - should be considering the maximum allowed and whether dropzone changed that value already or not
    this.emitFileRemovedEvent();
    this.dispatch('file-removed');
    this.showAvatarUpload();
  }

  deleteWithTurboRequest(event) {
    event.preventDefault();
    let path = event.currentTarget.getAttribute('href');
    destroy(path, { responseKind: 'turbo-stream' });
    this.showAvatarUpload();
  }

  emitFileRemovedEvent() {
    emit('dropzone-removedfile', { fileHiddenInputName: this.fileHiddenInputNameValue });
  }

  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = 'none';
  }

  trainingUploadFileAdded(file) {
    if (file.status == Dropzone.ADDED) {
      this.totalFiles += 1;
      let directUpload = createDirectUploadController(this, file);
      directUpload.trainingDirectUploadStart();
    }
  }

  voiceTrainingUploadFileAdded(file) {
    if (file.status == Dropzone.ADDED) {
      this.totalFiles += 1;
      let directUpload = createDirectUploadController(this, file);
      directUpload.voiceTrainingDirectUploadStart();
    }
  }

  bindEvents() {
    if (this.isModelTrainingValue) {
      this.dropZone.on('addedfile', (file) => {
        setTimeout(() => {
          this.trainingUploadFileAdded(file);
        }, 500);
      });
    } else if (this.isVoiceTrainingValue) {
      this.dropZone.on('addedfile', (file) => {
        setTimeout(() => {
          this.voiceTrainingUploadFileAdded(file);
        }, 500);
      });
    } else {
      this.dropZone.on('addedfile', (file) => {
        if (this.submitButton) {
          this.submitButton.disabled = true;
        }
        this.onFileAdded(file);
        // dispatch this so other controllers can handle things like disabling buttons
        this.dispatch('file-added', { detail: { enable: false } });
        setTimeout(() => {
          if (file.status == Dropzone.ADDED) {
            createDirectUploadController(this, file).start();
          }
        }, 500);
        emit('dropzone-addedfile', { fileHiddenInputName: this.fileHiddenInputNameValue });
      });
    }

    this.dropZone.on('removedfile', (file) => {
      file.controller && removeElement(file.controller.hiddenInput);
      this.emitFileRemovedEvent();
      this.dispatch('file-removed');
      this.showAvatarUpload();
    });

    this.dropZone.on('error', (file, message) => {
      showToast('danger', `${message} ${file.name}`);

      let errorHolderEl = findElement(file.previewTemplate, '[data-dz-errormessage]');
      if (errorHolderEl) {
        errorHolderEl.classList.add('text-danger');
      }
    });

    this.dropZone.on('canceled', (file) => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on('processing', () => {
      if (this.submitButton) {
        this.submitButton.disabled = true;
      }
    });

    this.dropZone.on('queuecomplete', () => {
      this.dispatch('addedfiles', { detail: { enable: true } });
      // in case they drag multiple files - shown with the message, on upload of accepted files - the extra's will be removed
      let rejectedFiles = this.dropZone.getRejectedFiles();
      rejectedFiles.forEach((file) => {
        this.dropZone.removeFile(file);
      });
    });

    this.dropZone.on('complete', (file) => {
      if (this.updateImageOnFinishValue) {
        let pathName = window.location.pathname;

        // clipdrop image update
        if (pathName.includes('/introduction')) {
          return this.onClipImageSourceUploaded(file, pathName);
        }

        post('/ai/image/update_init_image_preview', {
          query: {
            signed_id: file.controller.hiddenInput.value,
            aspect_ratio: this.getDesiredImageAspectRatio()
          },
          responseKind: 'turbo-stream'
        });
      }
      if (this.portraitImageUploadedValue) {
        post('/ai/video/portrait_image_uploaded', {
          query: {
            signed_id: file.controller.hiddenInput.value,
            aspect_ratio: this.getDesiredImageAspectRatio() || '0'
          },
          responseKind: 'turbo-stream'
        });
      }
      if (this.portraitAudioUploadedValue) {
        post('/ai/video/portrait_audio_uploaded', {
          query: {
            signed_id: file.controller.hiddenInput.value
          },
          responseKind: 'turbo-stream'
        });
        this.dispatch('voiceUploaded');
      }
      if (this.speechAudioUploadedValue) {
        post('/ai/audio/speech_audio_uploaded', {
          query: {
            signed_id: file.controller.hiddenInput.value
          },
          responseKind: 'turbo-stream'
        });
        this.dispatch('speechAudioUploaded');
      }
      this.remainingFiles = without(this.remainingFiles, file);
      if (this.submitButton) {
        this.submitButton.disabled = this.remainingFiles.length > 0;
      }

      if (this.isClipdropBackgroundRemovalValue && this.remainingFiles.length == 0) {
        let uploadedFilesSignedIds = [];
        Array.from(document.querySelectorAll('[name="image[init_image][signed_id][]"]')).forEach(
          (input) => uploadedFilesSignedIds.push(input.value)
        );
        this.submitToClipdropImageSourceUpload({
          signed_ids: uploadedFilesSignedIds,
          category: 'remove_background'
        });
      }
    });
  }

  getDesiredImageAspectRatio() {
    return document.querySelector('[name="image[settings][aspect_ratio]"] option[selected]')
      ?.dataset?.ratioValue;
  }

  createDropZone() {
    let previewNode = findElement(this.element, '#dropzone_template');
    previewNode.id = '';
    let previewTemplate = previewNode.parentNode.innerHTML;
    previewNode.parentNode.removeChild(previewNode);

    return new Dropzone(findElement(this.element, '.dropzone'), {
      url: this.url,
      headers: this.headers,
      maxFiles: this.dropZoneConfig.maxFiles,
      uploadMultiple: this.dropZoneConfig.uploadMultiple,
      maxFilesize: this.dropZoneConfig.maxFileSize,
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: false,
      autoQueue: false,
      previewTemplate: previewTemplate,
      previewsContainer: findElement(this.element, '.dropzone-previews')
    });
  }

  get previewElement() {
    return this.dropZone.options.previewsContainer;
  }

  onFileAdded(file) {
    this.remainingFiles.push(file);
    this.hideAvatarUpload();

    // This is like the DZ built in data-dz-name, but instead of injecting the name into the el's innerText, it injects it into the input el's value attr
    let nameInputEl = findElement(file.previewTemplate, '.dz-name-input');
    if (nameInputEl) {
      nameInputEl.setAttribute('value', file.name);
      nameInputEl.setAttribute('disabled', '');
    }

    // DZ automatically puts images as the data-dz-thumbnail (if under 10mb), after this function is called
    // So first we put the extension as the thumbnail, and if DZ can do a preview, it will afterwards
    let fileTypeExtension = last(file.upload.filename.toLowerCase().split('.'));
    // if we can't get file type from filename, use the type
    if (!fileTypeExtension.length) {
      fileTypeExtension = last(file.type.split('/'));
    }
    findElement(
      file.previewTemplate,
      '[data-dz-thumbnail]'
    ).src = `/mimetypes/${fileTypeExtension}.png`;
  }

  get headers() {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') };
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url');
  }

  get acceptedFiles() {
    return getAcceptedFilesFromFeature(this.dropZoneConfig.acceptedFilesFeature);
  }

  isFileTypeAccepted(fileType) {
    return includes(this.acceptedFiles.split(','), fileType);
  }

  get form() {
    return this.element.closest('form');
  }

  get submitButton() {
    return (
      findElement(this.form, 'input[type=submit], button[type=submit]') ||
      document.getElementById('update-organization-button')
    );
  }

  // for clipdrop implementation
  onClipImageSourceUploaded(file, pathName) {
    const imageClippingTypes = [
      'reimagine',
      'replace_background',
      'sketch_to_img',
      'remove_background',
      'dreamscale'
    ];
    const imageGenerationCategory = pathName
      .split('/')
      .filter((category) => imageClippingTypes.includes(category))[0];

    if (imageGenerationCategory) {
      this.submitToClipdropImageSourceUpload({
        signed_id: file.controller.hiddenInput.value,
        category: imageGenerationCategory,
        aspect_ratio: this.getDesiredImageAspectRatio()
      });
    }
  }

  submitToClipdropImageSourceUpload(body) {
    post('/ai/image/source_image_uploaded', { body }).then((response) => {
      response.json.then((value) => {
        if (value.redirect_path) {
          window.location.href = value.redirect_path;
        }
      });
    });
  }

  showAvatarUpload() {
    if (this.element.firstElementChild.classList.contains('avatar-dropzone')) {
      this.element.firstElementChild.classList.remove('d-none');
    }
  }
  hideAvatarUpload() {
    if (this.element.firstElementChild.classList.contains('avatar-dropzone')) {
      this.element.firstElementChild.classList.add('d-none');
    }
  }
  submitToModelTrainingImageSourceUpload(body) {
    return post('/ai/image/mixer_images_uploaded', { body, responseKind: 'turbo-stream' });
  }

  submitToVoiceTrainingImageSourceUpload(body) {
    return post('/ai/audio/voice_samples_uploaded', { body, responseKind: 'turbo-stream' });
  }

  visualProgressRefresh(file) {
    let progress = round(mean(Object.values(this.progressByFileUUID)), 1).toFixed(1);
    let uploadPercentageEl = findElement(file.previewTemplate, '.upload-progress');
    let matches = uploadPercentageEl.innerText.match(/(\d+.\d)/);
    let current_progress = matches ? matches[0] : 0;

    if (uploadPercentageEl && Number(current_progress) < progress) {
      uploadPercentageEl.innerText = `Uploading ${progress}%`;
    }
  }

  updateFilesProgress(file, progress) {
    let uuid = file.upload.uuid;
    this.progressByFileUUID[uuid] = progress;
    this.visualProgressRefresh(file);
  }
}

class DirectUploadControllerDropZone {
  constructor(source, file) {
    let url = source.url;
    if (!source.url.includes('service')) {
      if (file.type.includes('image') || file.type.includes('video')) {
        url = source.url.concat('?service=amazon_s3_imgix');
      } else {
        url = source.url.concat('?service=amazon_s3_private');
      }
    }
    this.directUpload = createDirectUpload(file, url, this);
    this.source = source;
    this.file = file;
  }

  trainingDirectUploadStart() {
    const mixerId = document.getElementById('mixer_id')?.value || '';
    this.directUpload.create((error, attributes) => {
      if (error) {
        return this.emitDropzoneError(error);
      }
      post('/ai/image/uploads/model_training/attach_and_resize', {
        body: { upload: { signed_id: attributes.signed_id, mixer_id: mixerId } },
        responseKind: 'turbo-stream'
      });

      this.source.processedFiles += 1;
      if (this.source.processedFiles == this.source.totalFiles) {
        const mixerMode = document.getElementById('mixer_mode')?.value || '';
        const mixerName = document.querySelector('[name="ai_mixer[name]"]')?.value || '';
        const mixerObjectPrompt =
          document.querySelector('[name="ai_mixer[object_prompt]"]')?.value || '';
        const mixerId = document.getElementById('mixer_id')?.value || '';

        setTimeout(() => {
          this.source.submitToModelTrainingImageSourceUpload({
            mixer_name: mixerName,
            mixer_mode: mixerMode,
            mixer_id: mixerId,
            mixer_object_prompt: mixerObjectPrompt
          });
        }, 300);
      }
    });
  }

  voiceTrainingDirectUploadStart() {
    const voiceId = document.getElementById('voice_id')?.value || '';
    this.directUpload.create((error, attributes) => {
      if (error) {
        return this.emitDropzoneError(error);
      }
      post('/ai/audio/uploads/voice_training/attach_file', {
        body: { upload: { signed_id: attributes.signed_id, voice_id: voiceId } },
        responseKind: 'turbo-stream'
      });

      this.source.processedFiles += 1;
      if (this.source.processedFiles == this.source.totalFiles) {
        const voiceId = document.getElementById('voice_id')?.value || '';

        setTimeout(() => {
          this.source.submitToVoiceTrainingImageSourceUpload({
            voice_id: voiceId
          });
        }, 300);
      }
    });
  }

  start() {
    this.file.controller = this;
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput = this.createHiddenInput(attributes.signed_id);
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput(value) {
    const input = document.createElement('input');
    input.type = 'hidden';
    if (this.source.fileHiddenInputNameValue.includes('UPLOAD_INDEX')) {
      input.name = this.source.fileHiddenInputNameValue.replace(
        /UPLOAD_INDEX/,
        this.source.uploadIndexValue
      );
    } else {
      input.name = this.source.fileHiddenInputNameValue;
    }
    input.value = value;

    if (this.source.uploadIndexValue >= 0) {
      // does the previewElement have a dropdown component
      let dropdown = this.file.previewElement.querySelector('[data-controller="dropdown"]');
      if (dropdown) {
        // replace dropdown attr value placeholder with the uploadIndex
        // example: data-dropdown-hidden-input-targeting-value="AssetTypeUPLOAD_INDEX"
        // will be replaced with data-dropdown-hidden-input-targeting-value="AssetType0"
        let attr = dropdown.getAttribute('data-dropdown-hidden-input-targeting-value');
        if (attr.includes('UPLOAD_INDEX')) {
          dropdown.setAttribute(
            'data-dropdown-hidden-input-targeting-value',
            attr.replace(/UPLOAD_INDEX/, this.source.uploadIndexValue)
          );
        }

        // replace all placeholder indices with correct index for hidden inputs of preview template that has a dropdown
        let hiddenInputs = dropdown.parentElement.querySelectorAll('input[type="hidden"]');
        if (hiddenInputs && hiddenInputs.length) {
          for (let hiddenInput of hiddenInputs) {
            if (hiddenInput.name.includes('UPLOAD_INDEX')) {
              hiddenInput.name = hiddenInput.name.replace(
                /UPLOAD_INDEX/,
                this.source.uploadIndexValue
              );
            }

            // for dropdown controller to find the input to update (AssetType input in case of attachments)
            if (hiddenInput.id.includes('UPLOAD_INDEX')) {
              hiddenInput.id = hiddenInput.id.replace(/UPLOAD_INDEX/, this.source.uploadIndexValue);
            }
          }
        }
      }
      insertAfter(input, this.file.previewTemplate.firstChild);
      this.source.uploadIndexValue++;
    }

    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener('progress', (event) => this.uploadRequestDidProgress(event));
  }

  uploadRequestDidProgress(event) {
    const progress = Math.floor((event.loaded / event.total) * 100);
    if (this.source.isModelTrainingValue) {
      return this.source.updateFilesProgress(this.file, progress);
    }

    let uploadPercentageEl = findElement(this.file.previewTemplate, '.dz-upload-percentage');
    if (uploadPercentageEl) {
      uploadPercentageEl.innerText = `Uploading ${progress}%`;
    }
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit('processing', this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit('error', this.file, error);
    this.source.dropZone.emit('complete', this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit('success', this.file);
    this.source.dropZone.emit('complete', this.file);

    let showEls = this.file.previewTemplate.querySelectorAll('.dz-show-on-success');
    for (let el of showEls) {
      el.removeAttribute('hidden');
    }

    let hideEls = this.file.previewTemplate.querySelectorAll('.dz-hide-on-success');
    for (let el of hideEls) {
      el.setAttribute('hidden', '');
    }

    let nameInputEl = findElement(this.file.previewTemplate, '.dz-name-input');
    if (nameInputEl) {
      nameInputEl.removeAttribute('disabled');
    }
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadControllerDropZone(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}
