/* eslint-disable no-undef */
import { Controller } from '@hotwired/stimulus';
// device-ios class for safari

export default class extends Controller {
  tomSelect = null;
  connect() {
    if (document.documentElement.classList.value.includes('device-ios')) {
      return;
    }

    if (this.element.tomselect) {
      return;
    }

    HSCore.components.HSTomSelect.init(`#${this.element.id}`, {
      onChange: (value) => {
        // Our homebrew implementation of an Option that we inject that's actually a Clear button
        if (value === '~clear~') {
          let tomSelect = this.element.tomselect;
          tomSelect.clear();

          // Put the placeholder back ourselves because TomSelect apparently can't be bothered
          let tsInput = tomSelect.input.parentElement.querySelector('.ts-input');
          if (tsInput && tomSelect.settings.placeholder) {
            tsInput.innerHTML = `<div class="ts-custom-placeholder">${tomSelect.settings.placeholder}</div>`;
          }
        }
      }
    });

    this.dispatch('initialized');

    // Fix Safari not considering options that have selected="true" to necessarily be selected
    for (let i = 0; i < this.element.options.length; i++) {
      let option = this.element.options[i];
      // If it has the selected attribute, but it's not considered selected yet
      if (option.getAttribute('selected') && !option.selected) {
        option.selected = true;
      }
    }

    setTimeout(
      function () {
        // in case the element is tied to another controller we want to only remove the reset-tomselect part
        let attr = this.element.getAttribute('data-controller');
        attr = attr.replace('reset-tomselect', '');
        this.element.setAttribute('data-controller', attr);
      }.bind(this),
      500
    );
    // We could probably delete HSTomSelect and just use TomSelect directly
  }
}
