import { emit } from './eventbus';
import { DirectUpload } from '@rails/activestorage';
import {post} from '@rails/request.js';
// import {formatBytes} from './index';
// import { isNil } from 'lodash';

// TODO document the flow
// TODO document how to use

class UploadManager {
  // constructor(options) {
  constructor() {
    this.uploadsByTemporaryId = new Map();
    // this.options = options || {}
  }

  startUpload(file, options = {}) {
    // Important to be unique, so when we get TurboStream updates, we only update the correct one, not a different one in a different tab
    const upload = new Upload(file, options);
    this.uploadsByTemporaryId.set(upload.temporaryId, upload);
    upload.start();
    return upload;
  }

  // DirectUpload learns this upon completion of the upload on its own, but if we can learn it earlier externally, we can include it in events earlier (otherwise just temporaryId)
  onLearnedKeyForTemporaryId(temporaryId, key) {
    let upload = this.uploadsByTemporaryId.get(temporaryId);
    upload.setKey(key);
    return upload;
  }

}

class Upload {
  constructor(file, options) {
    this.status = 'initializing'; // initializing, preProcessing, preparing (getting signed url url), uploading, postProcessing, done, error
    this.temporaryId = `turbo-uploader-file-tmp-id-${crypto.randomUUID()}`;
    this.file = file;
    this.options = options || {};
    this.context = this.options.context || '';
    this.key = null; // Known only after Blob is created in Rails
    this.signedId = null; // Known only after Blob is created in Rails

    if(!this.directUploadUrl){
      throw new Error('options.directUploadUrl is required');
    }
  }

  get directUploadUrl(){
    return `${this.options.directUploadUrl}&temporary_id=${this.temporaryId}&context=${this.context}`;
  }

  setKey(key){
    this.key = key;
  }

  start() {
    if(this.status !== 'initializing') return;

    this.directUploadController = new DirectUploadController(this);
    this.directUploadController.start();
  }

  onUploadingBegan(request){
    request.upload.addEventListener('progress', (event) => this.onUploadProgress(event));
  }

  onUploadProgress(event){
    const progress = Math.floor((event.loaded / event.total) * 100);
    this.emitEvent('turboUpload:uploadProgress', { progress, event });
  }

  onUploadComplete(blob){
    this.signedId = blob.signed_id;
    this.key = blob.key;
    this.emitEvent('turboUpload:uploadComplete', { blob });

    post(`/turbo_upload/${this.signedId}/after_uploaded`, {
      body: {
        context: this.context,
      },
      responseKind: 'turbo-stream'
    });
  }

  emitEvent(eventName, data = {}){
    emit(eventName, { upload: this, key: this.key, ...data });
  }

}

// Needed to upload to Rails ActiveStorage; Each Upload has 1 DirectUpload and 1 DirectUploadController (by necessity)
class DirectUploadController {
  constructor(upload) {
    this.upload = upload;
    this.directUpload = new DirectUpload(upload.file, upload.directUploadUrl, this);
  }

  start() {
    this.directUpload.create((error, blob) => {
      if (error) {
        throw new Error(error);
      } else {
        this.upload.onUploadComplete(blob);
      }
    });
  }

  // Called by DirectUpload
  directUploadWillStoreFileWithXHR(request) {
    this.upload.onUploadingBegan(request);
  }
}

// Gets a single Global instance
let uploadManager;
function getUploadManager() {
  if (!uploadManager) {
    uploadManager = new UploadManager();
  }
  return uploadManager;
}

export { UploadManager, getUploadManager };
