import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// Connects to data-controller="ai-grid"
export default class extends Controller {
  static targets = [
    'aiGrid',
    'gridSizeSmall',
    'gridSizeSmallMobile',
    'gridSizeMedium',
    'gridSizeMediumMobile',
    'imageToggleable',
    'switcher'
  ];
  static values = { url: String };

  gridSize = 'medium';

  switcherTargetConnected() {
    let size = localStorage.getItem('aiGridSize');
    if (size) {
      this.gridSize = size;
      this.refreshGridSize();
    } else {
      localStorage.setItem('aiGridSize', this.gridSize);
    }
  }

  toggleGridSize(event) {
    if (this.gridSize === 'small') {
      this.setGridSize('medium');
    } else {
      this.setGridSize('small');
    }
    event.preventDefault();
  }

  setGridSizeSmall(event) {
    this.setGridSize('small');
    if (document.getElementById('grid_size')) {
      document.getElementById('grid_size').value = 'SM';
    }
    event.preventDefault();
  }

  setGridSizeMedium(event) {
    this.setGridSize('medium');
    if (document.getElementById('grid_size')) {
      document.getElementById('grid_size').value = 'LG';
    }
    event.preventDefault();
  }

  setGridSize(gridSize) {
    this.gridSize = gridSize;
    localStorage.setItem('aiGridSize', this.gridSize);
    this.refreshGridSize();
  }

  refreshGridSize() {
    if (this.hasAiGridTarget) {
      if (this.gridSize === 'small') {
        this.gridSizeSmallTarget.classList.add('active');
        this.gridSizeMediumTarget.classList.remove('active');

        if (this.hasGridSizeSmallMobileTarget) {
          this.gridSizeSmallMobileTarget.classList.remove('d-none');
          this.gridSizeMediumMobileTarget.classList.add('d-none');
        }

        this.aiGridTarget.classList.add('grid-sm');
      } else if (this.gridSize === 'medium') {
        this.gridSizeSmallTarget.classList.remove('active');
        this.gridSizeMediumTarget.classList.add('active');

        if (this.hasGridSizeSmallMobileTarget) {
          this.gridSizeSmallMobileTarget.classList.add('d-none');
          this.gridSizeMediumMobileTarget.classList.remove('d-none');
        }

        this.aiGridTarget.classList.remove('grid-sm');
      }
    }
  }

  toggleFocusClass(event) {
    let imageClicked = event.currentTarget.closest('.ai-image');
    const historyId = imageClicked.dataset.historyId;
    // just remove it if it has it
    if (imageClicked.classList.contains('focus')) {
      imageClicked.classList.remove('focus');
      if (historyId) {
        this.updateHistoryRightPanel(historyId, 'false');
      }
      return;
    }

    // remove it from any other image that might have it currently
    for (let image of this.imageToggleableTargets) {
      image.classList.remove('focus');
    }
    imageClicked.classList.add('focus');
    if (historyId) {
      this.updateHistoryRightPanel(historyId, 'true');
    }
  }

  updateHistoryRightPanel(recordId, visibility) {
    post(this.urlValue, {
      query: { id: recordId, visibility },
      responseKind: 'turbo-stream'
    });
  }
}
