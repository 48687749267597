import { Controller } from '@hotwired/stimulus';

let TOOLBAR_CLASSES_MAPPING = {
  bold: '.trix-button--icon-bold',
  italic: '.trix-button--icon-italic',
  strike: '.trix-button--icon-strike',
  link: '.trix-button--icon-link',
  heading1: '.trix-button--icon-heading-1',
  quote: '.trix-button--icon-quote',
  code: '.trix-button--icon-code',
  bullet: '.trix-button--icon-bullet-list',
  number: '.trix-button--icon-number-list',
  descreaseNesting: '.trix-button--icon-decrease-nesting-level',
  increaseNesting: '.trix-button--icon-increase-nesting-level',
  attach: '.trix-button--icon-attach',
  undo: '.trix-button--icon-undo',
  redo: '.trix-button--icon-redo'
};

export default class extends Controller {
  static values = {
    icons: Array
  };

  connect() {
    let iconsToHide = this.iconsToHide;
    // eslint-disable-next-line no-unused-vars
    addEventListener('trix-initialize', function (event) {
      Object.values(iconsToHide).forEach((iconClass) => {
        document.querySelector(iconClass).remove();
      });
    });
  }

  get iconsToHide() {
    return (
      Object.entries(TOOLBAR_CLASSES_MAPPING)
        // eslint-disable-next-line no-unused-vars
        .filter(([key, value]) => !this.iconsValue.includes(key))
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {})
    );
  }
}
