import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// TODO this controller should be renamed for ai renaming
export default class extends Controller {
  static targets = [
    'select',
    'createCount',
    'createSentiment',
    'createImageSampler',
    'createQuantity',
    'mixers',
    'counter',
    'intermediateDosageCards'
  ];
  showCreateCountFor = ['Custom', 'Email'];
  showCreateImageSamplerFor = ['IZEA Form: Portrait', 'IZEA Form: Art'];
  showMixersFor = ['Felli'];

  connect() {
    this.updateCounter();
    if (this.hasCreateCountTarget) {
      this.createCountParent = this.createCountTarget.closest('.create-count');
      this.checkCreateLengthForCreateCount(this.selectTarget.value);
      this.selectTarget.addEventListener(
        'change',
        function (e) {
          const value = e.target.value;
          this.checkCreateLengthForCreateCount(value);
        }.bind(this)
      );
    }

    if (this.hasCreateImageSamplerTarget) {
      this.createImageSamplerParent =
        this.createImageSamplerTarget.closest('.create-image-sampler');
      this.checkCreateImageModelSelection(this.selectTarget.value);
      this.selectTarget.addEventListener(
        'change',
        function (e) {
          const value = e.target.value;
          this.checkCreateImageModelSelection(value);
        }.bind(this)
      );
    }

    if (this.hasMixersTarget) {
      this.mixersParent = this.mixersTarget.closest('.mixers');
      if (this.showMixersFor.includes(this.selectTarget.value)) {
        this.mixersParent.style = '';
      } else {
        this.mixersParent.style.display = 'none';
      }
      this.selectTarget.addEventListener(
        'change',
        function (e) {
          const value = e.target.value;
          if (this.showMixersFor.includes(value)) {
            this.mixersParent.style = '';
          } else {
            this.mixersParent.style.display = 'none';
          }
        }.bind(this)
      );
    }
  }

  afterTomSelectInitialization() {
    this.checkCreateLengthForCreateCount(this.selectTarget.value);
  }

  checkCreateLengthForCreateCount(value) {
    if (this.showCreateCountFor.includes(value)) {
      this.createCountParent.style = '';
    } else {
      this.createCountParent.style.display = 'none';
      this.createCountTarget.value = '';
    }
  }

  checkCreateImageModelSelection(value) {
    if (this.showCreateImageSamplerFor.includes(value)) {
      this.createImageSamplerParent.style = '';
    } else {
      this.createImageSamplerParent.style.display = 'none';
      this.createImageSamplerParent.value = 'Euler a';
    }
  }

  updateGenreSubCategory() {
    if (this.selectTarget.value) {
      const genre = this.selectTarget.value;
      post('/ai/image/genre_sub_category', {
        query: { genre },
        responseKind: 'turbo-stream'
      });
    }
  }

  defaultCameraOptions() {
    if (this.selectTarget.value) {
      const model = this.selectTarget.value;
      const category = window.location.pathname.split('/').pop();
      const hasCustomPrompts = localStorage.getItem('custom_prompts');
      post('/ai/image/selected_ai_model', {
        query: { model, category, has_custom_prompts: hasCustomPrompts },
        responseKind: 'turbo-stream'
      });
    }
  }

  updateCounter() {
    const hideCounter = this.hasMixersTarget || this.hasIntermediateDosageCardsTarget;
    if (this.hasCounterTarget && !hideCounter) {
      let counter = 0;
      this.element.querySelectorAll('select').forEach((select) => {
        if (select.value) {
          counter++;
        }
      });
      this.counterTarget.innerText = counter > 0 ? `(${counter})` : '';
    }
    this.dispatch('counter');
  }

  flagCustomSelection() {
    if (this.hasCounterTarget) {
      localStorage.setItem('custom_prompts', true);
    }
  }

  selected(event) {
    this.element.querySelectorAll('.selected').forEach((element) => {
      element.classList.remove('selected');
    });
    event.currentTarget.classList.add('selected');
  }
}
