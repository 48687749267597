import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

// Connects to data-controller="ai-histories"
export default class extends Controller {
  static targets = ['singleImageView', 'parentGroupView', 'imageViewSwitcher'];

  static values = { singleMode: Boolean };

  imageViewSwitcherTargetConnected() {
    if (this.singleModeValue) {
      this.singleImageViewTarget.classList.add('active');
      this.parentGroupViewTarget.classList.remove('active');
    } else {
      this.singleImageViewTarget.classList.remove('active');
      this.parentGroupViewTarget.classList.add('active');
    }
  }

  showSingleImageView(event) {
    event.preventDefault();
    this.singleImageViewTarget.classList.add('active');
    this.parentGroupViewTarget.classList.remove('active');
    if (window.location.href.includes('/community/manage')) {
      post('/ai/community/show_single_image_view', {
        body: {
          search: document.querySelector('[name="history[prompt]"]')?.value
        },
        responseKind: 'turbo-stream'
      });
    }
    if (window.location.href.includes('/histories/image')) {
      post('/ai/histories/show_single_image_view', {
        body: {
          search: document.querySelector('[name="history[prompt]"]')?.value
        },
        responseKind: 'turbo-stream'
      });
    }
  }

  showParentGroupView(event) {
    event.preventDefault();
    this.singleImageViewTarget.classList.remove('active');
    this.parentGroupViewTarget.classList.add('active');

    if (window.location.href.includes('/community/manage')) {
      post('/ai/community/show_parent_group_view', {
        body: {
          search: document.querySelector('[name="history[prompt]"]')?.value
        },
        responseKind: 'turbo-stream'
      });
    }
    if (window.location.href.includes('/histories/image')) {
      post('/ai/histories/show_parent_group_view', {
        body: {
          search: document.querySelector('[name="history[prompt]"]')?.value
        },
        responseKind: 'turbo-stream'
      });
    }
  }
}
