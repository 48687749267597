import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash';
import { getUploadManager } from '../helpers/upload_manager';
import { subscribeAll } from '../helpers/eventbus';
import { getAcceptedFilesFromFeature } from '../helpers/accepted_file_types';
import { showToast } from '../helpers/toast_functions';

// function readAsDataURL(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = () => reject(reader.error);
//     reader.readAsDataURL(file);
//   });
// }

// Connects to data-controller="turbo-uploader"
export default class extends Controller {
  static targets = ['dropAreaVisual', 'filesHolder', 'file', 'inputUpload'];
  fileElementByKey = new Map();

  static values = {
    context: String, // Enables the uploader / turbo stream websocket channel when set, allows template to be different
    acceptedFilesFeature: String // The feature that determines the accepted file types
  };

  connect() {
    this.unsubscribeAll = subscribeAll(this, {
      'turboUpload:uploadProgress': this.onUploadProgress
      // 'turboUpload:uploadComplete': this.onUploadComplete,
    });

    this.hideDropAreaVisualDebounced = debounce(this.hideDropAreaVisual.bind(this), 100);

    // Set the accepted files for the input element / file picker
    if (this.hasInputUploadTarget) {
      this.inputUploadTarget.accept = this.acceptedFiles;
    }
  }

  disconnect() {
    this.unsubscribeAll();
  }

  get acceptedFiles() {
    return getAcceptedFilesFromFeature(this.acceptedFilesFeatureValue);
  }

  async fileTargetConnected(fileEl) {
    let { key, temporaryId } = fileEl.dataset;
    this.fileElementByKey.set(key, fileEl);
    // DirectUpload doesn't tell us the blob key until after the upload is complete, but the TurboStream can tell us as the upload is beginning, so we can then react to upload progress events
    // Without this, the progress events would not include the key, as the Upload would not know it yet
    if (temporaryId) {
      this.uploadManager.onLearnedKeyForTemporaryId(temporaryId, key);

      // Could use this to already show the image as it's uploading, but it causes the flicker as the image has to be reloading after upload complete, so maybe nicer to just show filetype placeholder during upload, and get it nicely formated with ImgIx after
      // let upload = this.uploadManager.onLearnedKeyForTemporaryId(temporaryId, key);
      // let imgEl = fileEl.querySelector('img[data-is-image-upload]');
      // if(imgEl){
      //   imgEl.src = await readAsDataURL(upload.file);
      // }
    }
  }

  onUploadProgress(event) {
    let { key, progress } = event;
    if (!key) return;
    let fileEl = this.fileElementByKey.get(key);
    fileEl.querySelector('[data-progress]').innerText = `${progress}%`;
  }

  showDropAreaVisual() {
    this.hideDropAreaVisualDebounced.cancel();
    this.dropAreaVisualTarget.classList.remove('invisible');
  }

  hideDropAreaVisual() {
    this.dropAreaVisualTarget.classList.add('invisible');
  }

  pickFiles() {
    // pop up file picker
    this.inputUploadTarget.click();
  }

  onFilesPicked() {
    [...this.inputUploadTarget.files].forEach((file) => {
      this.startUpload(file);
    });
  }

  onFilesDropped(event) {
    event.preventDefault();
    this.hideDropAreaVisual();

    // Use DataTransfer interface to access the file(s)
    [...event.dataTransfer.files].forEach((file) => {
      this.startUpload(file);
    });
  }

  onFilesDragEnter() {
    this.showDropAreaVisual();
  }
  onFilesDragOver(event) {
    event.preventDefault();
    this.showDropAreaVisual();
  }
  onFilesDragLeave() {
    this.hideDropAreaVisualDebounced();
  }

  get directUploadUrl() {
    return this.element.getAttribute('data-direct-upload-url');
  }

  get uploadManager() {
    return getUploadManager();
  }

  startUpload(file) {
    // Enforce accepted files for drag and drop
    let extension = file.name.split('.').pop();
    if (this.acceptedFiles && !this.acceptedFiles.includes(`/${extension}`)) {
      showToast('danger', `You can't upload files of this type: ${file.name}`);
      return;
    }

    let upload = this.uploadManager.startUpload(file, {
      directUploadUrl: this.directUploadUrl,
      context: this.contextValue
    });

    let div = document.createElement('div');
    // div.className = 'd-none';
    div.id = upload.temporaryId;
    this.filesHolderTarget.appendChild(div);
  }
}
